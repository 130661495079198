<template>
  <div class="bg">
    <v-form v-model="valid" ref="form" class="pt-16">
      <v-row class="justify-center">
        <v-col cols="12" md="6" sm="8">
          <v-card elevation="1" class="mx-auto rounded-lg">
            <v-card-title class="pb-0 mb-0 text-h5 font-weight-bold">
              <v-btn @click="back" class="ms-8" icon><v-icon class="mr-1" color="orange">mdi-arrow-left</v-icon> Back</v-btn>
            </v-card-title>
            <v-row class="justify-center">
              <v-col cols="12" md="10" sm="10" class="pb-16 px-8">
                <div class="d-flex flex-column mt-4 font-weight-bold subtitle-1 text-center">
                  RESET PASSWORD
                </div>
                <v-divider></v-divider>
                <div class="mt-4 mx-2 text-center subtitle-2 font-weight-light grey--text">
                  Enter your registered WhatsApp address.
                  We will send a verification code to reset your password.
                </div>
                <v-row class="mt-6">
                  <v-col cols="12">
                    <v-text-field
                        v-model="user.username"
                        label="NIP/email (User name)"
                        hide-details="auto"
                        outlined
                        dense
                        prepend-inner-icon="mdi-account"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="user.phone"
                        label="WhatsApp"
                        prepend-inner-icon="mdi-whatsapp"
                        :rules="[validateNotEmpty, validatePhoneNumber]"
                        outlined
                        dense
                        placeholder="08...."
                        hint="Ex. 082xxxxxxxx"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="text-center mt-n6 mb-10">
                  <div  class="caption red--text" v-if="errorMessage">
                    {{ errorMessage }}
                  </div>
                </div>


                <v-card-actions class="mt-4">
                  <div class="flex-fill">
                    <v-btn color="primary" @click="handleNext" block :disabled="!valid">
                      Next</v-btn>
                  </div>
                </v-card-actions>
                <v-divider class="mt-10"></v-divider>
                <div class="ps-4 mt-4 d-flex align-baseline">
                  <span class="grey--text subtitle-2 text--darken-2">
                    Don’t have an account?
                  </span>
                  <router-link to="/register" style="text-decoration: none !important;">
                    <span class="blue--text ml-2 subtitle-1 font-weight-bold">Register Here</span>
                  </router-link>
                </div>

              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>


    <v-spacer></v-spacer>
  </div>
</template>

<script>

import User from "@/models/user";
import ApiFonnteService from "@/services/apiservices-ext/api-fonnte-service";
import SimpleMessage from "@/models/payload/simple-message";
import UserService from "@/services/user-service";
import FtRegistrationReq from "@/models/account-registration/ft-registration-req";
import FtRegistrationRegService from "@/services/apiservices-account-registration/ft-registration-reg-service";

export default {
  name: "ResetAturUlang",
  components: {},
  data() {
    return {
      showPassword: false,
      showRetypePassword: false,
      valid: false,
      errorMessage:'',
      rulesNotEmtpy: [(v) => !!v || "Cannot be empty"],
      validateNotEmpty: (value) => !!value || "Cannot be empty",

      rulesMinLength: (v) => {
        return (v && v.length >=6) || "Must be at least 6 characters long";
      },

      validateEmail: (value) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value) || "Wrong email format";
      },

      validatePhoneNumber: (value) => {
        const phoneRegex = /^\d+$/;
        return phoneRegex.test(value) || "Nomor WhatsApp harus berupa angka";
      },

      itemsFDivision: [],
      user: new User("", ""),
      loading: false,
      message: "",
      success: false,

    };
  },
  computed: {
  },
  created() {
  },
  methods: {
    back(){
      this.$router.push("/login")
    },
    handleNext(){
      let ftRegistrationReq = new FtRegistrationReq();
      ftRegistrationReq.type = 2
      ftRegistrationReq.tokenMedia = 1;

      // ftRegistrationReq.email = this.user.username; //Jika Username Menggunakan Email
      ftRegistrationReq.username = this.user.username;
      ftRegistrationReq.whatsApp = Number(this.user.phone);

      ftRegistrationReq.timeStart = new Date().toISOString()
      ftRegistrationReq.timeEnd = new Date().toISOString()


      FtRegistrationRegService.checkAndCreateResetPasswordReqPublic(ftRegistrationReq).then(
          response =>{
            this.$store.dispatch('registration/setItemFtReg', response.data);
            this.$router.push("/reset-masukkan-otp-password");
          },
          error => {
            if (error.response.data.message.includes('error: Email is not registered')){
              this.errorMessage = "Email is not registered";
            } else {
              this.errorMessage = "Other error"
            }

          }
      )

    },

    handleNext_Wa_Old() {

      const min = 1000; // Angka terkecil (4 digit)
      const max = 9999; // Angka terbesar (4 digit)
      const rand4 =  Math.floor(Math.random() * (max - min + 1)) + min;


      const simpleMsg = new SimpleMessage(
          "RESET PASSWORD BKPSDM KOTA PALANGKARAYA:",
          `${this.user.phone}`,
          `Kode OTP:  ${rand4}`
        );

      const whatsappNumber = parseInt(this.user.phone);
      UserService.getIdByWhatsappNumber(whatsappNumber).then(
          (response) => {
            if (response.data) {
              const user = response.data;
              ApiFonnteService.postFonnteSendMessagePublic(simpleMsg).then(
                  () => {
                    this.$store.dispatch('registration/setTokenOtp', `${rand4}`);
                    this.$store.dispatch('registration/setWhatsAppNumber', this.user.phone);
                    this.$store.dispatch('registration/setTokenExpired', user.expiredTimeMillis);
                    this.$store.dispatch('registration/setNamaLengkap', user.fullName);

                    this.$router.push("/reset-masukkan-otp");
                  }
              )
            }
          },
          () => {
            this.errorMessage = "Nomor WhatsApp tidak terdaftar";
          }
      )


    },
  },
  mounted() {
  },
};
</script>
<style scoped>
.bg {
  background: #edf1f3;
  width: 100%;
  height: 100vh;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

</style>


