<template>
  <div>
    <ResetAturUlang></ResetAturUlang>
  </div>
</template>

<script>
import ResetAturUlang from "@/components/reset-password/ResetAturUlang.vue";

export default {
  name: "ResetAturUlangView",
  components: {
    ResetAturUlang
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style scoped>
</style>


