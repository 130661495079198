export default class SimpleMessage {
  constructor(
      subject = "BKPSDM",
      to= "", //Nomor Whatsapp
      message = ""

  ) {
    this.subject = subject;
    this.to = to;
    this.message = message;
  }

}
